import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { catchError, switchMap } from 'rxjs/operators';
import { BehaviorSubject, of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  redirectLink!:string
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { 
   this.redirectLink =  window.location.href
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authenticationService.currentUserToken;
    if (token?.idToken) {
      if (this.authenticationService.isUserLoginedHasValidToken()) {
        return true;
      } else {
        this.refreshTokenSubject.next(null);
        await this.authenticationService.refreshToken().pipe(
          switchMap((isValid: boolean) => {
            const newToken = this.authenticationService.currentUserToken;
            this.refreshTokenSubject.next(newToken.idToken);
            return of(isValid);
          }),
          catchError((err) => {
            console.log('token expired');
            this.authenticationService.logout();
            this.router.navigate(['/']);
         

            return throwError(err);
          })
        ).subscribe();
      }
    }
    else {
      this.authenticationService.logout();
      this.router.navigate(['/']);
      window.sessionStorage.setItem('redirectLink', this.redirectLink)
      
    }
  }
}
