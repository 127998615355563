import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewMailIssueComponent } from './view-mail-issue.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [ViewMailIssueComponent],
  imports: [
    CommonModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: ViewMailIssueComponent,
      },
    ]),
  ]
})
export class ViewMailIssueModule { }
