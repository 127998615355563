import { FormGroup } from '@angular/forms';

export function DateTimeGreaterThanToday(controlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.errors) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    ;
    if (new Date(control.value) <= new Date()) {
      control.setErrors({ greaterThanToday: true });
    } else {
      control.setErrors(null);
    }
  };

}
export function DateTimeToGreaterThanFrom(from: string, to: string) {
  return (formGroup: FormGroup) => {
    const controlFrom = formGroup.controls[from];
    const controlTo = formGroup.controls[to];
    if (controlTo.errors || controlFrom.errors) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    ;
    if (new Date(controlTo.value) <= new Date(controlFrom.value)) {
      controlTo.setErrors({ DateFromgreaterThanTo: true });
    } else {
      controlTo.setErrors(null);
    }
  };

}

export function textValidator(controlName: string, isWhiteSpaceValidation: boolean = false, min: number = 0, max: number = 0) {

  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    if (control.errors) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    if (isWhiteSpaceValidation) {
      if ((control.value || '').trim().length === 0) {
        control.setErrors({ cannotContainSpace: true });
      } else {
        if (max == 0 && min > 0) // optinal 
        {
          if ((control.value || '').trim().length < min) {
            control.setErrors({ length: true });
          }
          else {
            control.setErrors(null);
          }
        }
        else {
          if (max > 0 && min > 0) {
            if ((control.value || '').trim().length < min || control.value.trim().length > max) {
              control.setErrors({ length: true });
            } else {
              control.setErrors(null);
            }
          }
        }
      }
    }
    else {
      if (max == 0 && min > 0) // optinal 
      {
        if (!((control.value || '').trim().length === 0)) {
          if ((control.value || '').trim().length < min ) {
            control.setErrors({ length: true });
          }
          else {
            control.setErrors(null);
          }
        }
        else {
          control.setErrors(null);
        }
      }
      else {
        if (max > 0 && min > 0) {
          if (!((control.value || '').trim().length === 0)) {
            if ((control.value || '').trim().length < min || control.value.trim().length > max) {
              control.setErrors({ length: true });
            } else {
              control.setErrors(null);
            }
          }
          else {
            control.setErrors(null);
          }
        }
      }
    }
  }
}

export function bothChecked(first: string, second: string) {
  return (formGroup: FormGroup) => {
    const controlfirst = formGroup.controls[first];
    const controlsecond = formGroup.controls[second];
    if (controlfirst.errors && controlsecond.errors) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    
    if (controlfirst.value == controlsecond.value && controlfirst.value == true) {
      controlsecond.setErrors({ cannotBothChecked: true });
      controlfirst.setErrors({ cannotBothChecked: true });
    } else {
      controlfirst.setErrors(null);
      controlsecond.setErrors(null);
    }
  };

}
