// https://www.youtube.com/watch?v=TkCKqeYjpv0

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from 'angularx-social-login';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { LoginTypeEnum } from 'src/app/@AppService/Enums/login.type.enum';
import { LoginUser, SocialLoginUser } from 'src/app/@core/auth/app-user';
import { AuthenticationService } from 'src/app/@core/auth/authentication.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  public googleUser: SocialUser = new SocialUser();
  googleLoginOptions = {
    scope: 'email profile openid',
  };

  loginForm: FormGroup;
  hasError: boolean;
  isLoading$: Observable<boolean>;
  private unsubscribe: Subscription[] = [];
  constructor(
    private router: Router,
    private msalService: MsalService,
    private auth: AuthenticationService,
    private googleService: SocialAuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initForm();
    if (this.route.snapshot.fragment) {
      this.loginWithMicrosoft();
    }
    this.checkUserStatus();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }
  initForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320),
        ]),
      ],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  submit() {
    this.hasError = false;
    const token = this.auth.currentUserToken;
    if (token?.idToken) {
      if (this.auth.isUserLoginedHasValidToken()) {
        this.router.navigate(['/dashboard']);
      } else {
        this.auth.logout();
      }
    } else {
      const loginInfo: LoginUser = this.loginForm.value;
      const loginSubscr = this.auth
        .loginUser(loginInfo)

        .subscribe((loginResponse) => {
          if (
            loginResponse.isValidResponse &&
            loginResponse.result.responseData
          ) {
            this.auth.login(
              '',
              loginResponse.result.responseData.userEmail,
              loginResponse.result.responseData.userName,
              '',
              loginResponse.result.responseData.authToken,
              loginResponse.result.responseData.authToken,
              loginResponse.result.responseData.refreshToken,
              LoginTypeEnum.JWT
            );
            this.checkUserStatus();
          } else {
            this.hasError = true;
            this.changeDetection.detectChanges();
          }
        });
      this.unsubscribe.push(loginSubscr);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  checkUserStatus() {
    const token = this.auth.currentUserToken;
    if (token?.idToken) {
      if (this.auth.isUserLoginedHasValidToken()) {
        this.router.navigate(['/dashboard']);
      //  let redirectLink =  window.sessionStorage.getItem('redirectLink')
      //   if (redirectLink != null) {
      //     window.location.href = redirectLink
      //   }
      } else {
        this.auth.logout();
      }
    }
  }

  loginWithMicrosoft() {
    this.hasError = false;
    const token = this.auth.currentUserToken;
    if (token?.idToken) {
      if (this.auth.isUserLoginedHasValidToken()) {
        this.router.navigate(['/dashboard']);
      } else {
        this.auth.logout();
      }
    } else {
      this.msalService.instance.handleRedirectPromise().then((res) => {
        if (res != null && res.account != null) {
          const loginInfo: SocialLoginUser = {
            email: res.account.username,
          };
          const loginSubscr = this.auth
            .socialLoginUser(loginInfo)

            .subscribe((loginResponse) => {
              if (
                loginResponse.isValidResponse &&
                loginResponse.result.responseData
              ) {
                this.auth.login(
                  '',
                  loginResponse.result.responseData.userEmail,
                  loginResponse.result.responseData.userName,
                  '',
                  loginResponse.result.responseData.authToken,
                  loginResponse.result.responseData.authToken,
                  loginResponse.result.responseData.refreshToken,
                  LoginTypeEnum.JWT
                );
                this.checkUserStatus();
              } else {
                this.hasError = true;
              }
            });
          this.unsubscribe.push(loginSubscr);
          //this.checkUserStatus();
        } else {
          if (this.msalService.loginRedirect()) {
            this.checkUserStatus();
          }
        }
      });
    }
  }

  public loginWithGoogle(): void {
    const token = this.auth.currentUserToken;
    if (token?.idToken) {

      if (this.auth.isUserLoginedHasValidToken()) {
        this.router.navigate(['/dashboard']);
      } else {
        this.auth.logout();
      }
    } else {
      this.googleService
        .signIn(GoogleLoginProvider.PROVIDER_ID, this.googleLoginOptions)
        .then((res) => {
          if (res != null && res.idToken != null) {

            this.googleUser = res;

            // this.auth.login(
            //   this.googleUser,
            //   this.googleUser.email,
            //   this.googleUser.name,
            //   this.googleUser.photoUrl,
            //   this.googleUser.idToken,
            //   this.googleUser.response.access_token,
            //   '',
            //   LoginTypeEnum.Google
            // );
            const loginInfo: SocialLoginUser = {
              email: this.googleUser.email,
            };
            const loginSubscr = this.auth
              .socialLoginUser(loginInfo)

              .subscribe((loginResponse) => {
                if (
                  loginResponse.isValidResponse &&
                  loginResponse.result.responseData
                ) {
                  this.auth.login(
                    '',
                    loginResponse.result.responseData.userEmail,
                    loginResponse.result.responseData.userName,
                    '',
                    loginResponse.result.responseData.authToken,
                    loginResponse.result.responseData.authToken,
                    loginResponse.result.responseData.refreshToken,
                    LoginTypeEnum.JWT
                  );
                  this.checkUserStatus();
                } else {
                  this.hasError = true;
                }
              });
            this.unsubscribe.push(loginSubscr);
          }
        })
        .catch((data) => {
          this.auth.logout();
        });
    }
  }
}
