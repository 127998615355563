<div class="login-container">
  <div class="login-form-container">
    <!-- splash screen -->
    <div class="d-flex flex-column flex-root h-100" id="kt_login_wrapper">
      <!-- <img src="./assets/media/logos/logo.svg" alt="Logo" />
  <button class="btn btn-success" (click)="loginWithMicrosoft()"> Login with Microsoft</button>
  <img src="./assets/media/logos/logo.svg" alt="Logo" />
  <button class="btn btn-success" (click)="loginWithGoogle()"> Login with Google </button>
  <img src="./assets/media/logos/logo.svg" alt="Logo" />
  <button class="btn btn-success" (click)="signOutFromGoogle()"> Sign out from Google </button>
   -->
      <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login">
        <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          <div class="login-form login-signin">
            <form class="form form-label-right" [formGroup]="loginForm" novalidate="novalidate"
              id="kt_login_signin_form" (ngSubmit)="submit()">
              <ng-container *ngIf="hasError">
                <div class="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div class="alert-text">Invalid email or password</div>
                </div>
              </ng-container>
              <!-- end::Alert error-->
              <div class="col-lg-12 center">
                <!--begin::Form group-->
                <div class="form-group row">
                  <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="email" name="email"
                    formControlName="email" autocomplete="off" [ngClass]="{
                      'is-invalid': loginForm.controls['email'].invalid
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Email is required',
                      control: loginForm.controls['email']
                    }"></ng-container>
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'email',
                      message: 'Email is invalid',
                      control: loginForm.controls['email']
                    }"></ng-container>
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'minLength',
                      message: 'Email should have at least 3 symbols',
                      control: loginForm.controls['email']
                    }"></ng-container>
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'maxLength',
                      message: 'Email should have maximum 360 symbols',
                      control: loginForm.controls['email']
                    }"></ng-container>
                </div>
                <!--end::Form group-->

                <!--begin::Form group-->
                <div class="form-group row">
                  <div class="d-flex justify-content-between mt-n5">
                    <label class="font-size-h6 font-weight-bolder text-dark pt-5">Password</label>
                  </div>

                  <input class="form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="password"
                    name="password" autocomplete="off" formControlName="password" [ngClass]="{
                      'is-invalid': loginForm.controls['password'].invalid
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Password is required',
                      control: loginForm.controls['password']
                    }"></ng-container>
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'minlength',
                      message: 'Password should have at least 3 symbols',
                      control: loginForm.controls['password']
                    }"></ng-container>
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'maxLength',
                      message: 'Password should have maximum 100 symbols',
                      control: loginForm.controls['password']
                    }"></ng-container>
                </div>
                <!--end::Form group-->

                <!--begin::Action-->
                <div class="pb-lg-0 pb-5">
                  <button type="submit" id="kt_login_signin_submit"
                    class="w100 d-block btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                    [disabled]="loginForm.invalid">
                    Sign In
                  </button>

                  <!-- <button type="button"
                    class="w100 d-block btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                    (click)="loginWithGoogle()">
                    <span class="svg-icon svg-icon-md">
                      <img src="./assets/media/svg/social-icons/google.svg" />
                    </span>
                    Sign in with Google
                  </button> -->
                  <!--   <button
                    type="button"
                    class="w100 d-block btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                    (click)="loginWithMicrosoft()"
                  >
                    <span class="svg-icon svg-icon-md">
                      <img
                        src="./assets/media/svg/social-icons/azure.png"
                        width="20px"
                      />
                    </span>
                    Sign in with Azure
                  </button> -->
                  <ng-container *ngIf="isLoading$ | async">
                    <span class="spinner spinner-primary ml-5"></span>
                  </ng-container>
                </div>
              </div>
              <!--end::Action-->
            </form>
          </div>
        </div>
      </div>
    </div>
    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
      <ng-container *ngIf="
          control.hasError(validation) && (control.dirty || control.touched)
        ">
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            {{ message }}
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>