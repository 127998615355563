import { AtmoizerUrls, IssueUrls } from '../Common/ServiceUrls';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TableService } from 'src/app/_metronic/shared/crud-table';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CheckScheduleIssueDto, IssueDto, ScheduleIssueDto, SearchParsingIssueData } from '../models/atomizer.model';
import { ApiResponse } from 'src/app/_metronic/shared/crud-table/models/response.model';
import { Observable } from 'rxjs';
import { GetMailChimpIssueReportQuery, MailChimpIssueReportDto } from '../models/mailChimp.issue';


@Injectable({
  providedIn: 'root',
})
export class ParsingIssueService extends TableService<IssueDto> implements OnDestroy {

  scheduleIssueUrl : string ; 
  checkScheduleIssueAvailabilityUrl : string ; 
  IssueCategoryDDL: string;
  GetParsingIssueChild: string;
  getIssueDataUrl: string;
  getIssueDatawithoutLinkUrl: string;

  getMailChimpListsUrl:string;
  getMailChimpTemplatesUrl:string;
  getMailChimpTemplateContentUrl:string;
  constructor(@Inject(HttpClient) http) {
    super(http);

    this.Urls.ListUrl = environment.publisherApiBaseUrl + AtmoizerUrls.ListIssue;
    this.GetParsingIssueChild = environment.publisherApiBaseUrl + AtmoizerUrls.GetParsingIssueChild;
    this.getIssueDataUrl = environment.publisherApiBaseUrl + IssueUrls.getIssueDataUrl;
    this.getIssueDatawithoutLinkUrl = environment.publisherApiBaseUrl + IssueUrls.getIssueDataWithoutLinkUrl;

    this.getMailChimpListsUrl = environment.publisherApiBaseUrl + IssueUrls.getMailChimpListsUrl;
    this.getMailChimpTemplatesUrl = environment.publisherApiBaseUrl + IssueUrls.getMailChimpTemplatesUrl;
    this.getMailChimpTemplateContentUrl = environment.publisherApiBaseUrl + IssueUrls.getMailChimpTemplateContentUrl;
    this.scheduleIssueUrl = environment.publisherApiBaseUrl + IssueUrls.scheduleIssueUrl;
    this.checkScheduleIssueAvailabilityUrl = environment.publisherApiBaseUrl + IssueUrls.checkScheduleIssueAvailabilityUrl;
  }
  getParsingIssueChild(parsingIssueId: string, languageId: number, editionId: number): Observable<ApiResponse<number>> {
    let url = `${this.GetParsingIssueChild}?parsingIssueId=${parsingIssueId}&languageId=${languageId}&editionId=${editionId}&websiteId=${this.currentWebsiteId}`;
    return this.http.get<ApiResponse<number>>(url);
  }

  getIssueData(searchDto: SearchParsingIssueData) {
    return this.http.post(this.getIssueDataUrl, searchDto)
  }
  getIssuewithoutLinkData(searchDto: SearchParsingIssueData) {
    return this.http.post(this.getIssueDatawithoutLinkUrl, searchDto)
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  getIssuesReport(): Observable<ApiResponse<MailChimpIssueReportDto[]>> {
    let url = environment.publisherApiBaseUrl + IssueUrls.GetParsingIssueListReportUrl+`?websiteId=${this.currentWebsiteId}`;
    return this.http.get<ApiResponse<MailChimpIssueReportDto[]>>(url);
  }
  getIssueStatistics(model :GetMailChimpIssueReportQuery) : Observable<ApiResponse<MailChimpIssueReportDto[]>> {
    let url = environment.publisherApiBaseUrl + IssueUrls.GetParsingIssueReportUrl;
    return this.http.post<ApiResponse<MailChimpIssueReportDto[]>>(url, model);
  }
  shceduleIssue(model :ScheduleIssueDto) :Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(this.scheduleIssueUrl, model);
  }
  checkScheduleIssueAvailability(model :CheckScheduleIssueDto) :Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(this.checkScheduleIssueAvailabilityUrl, model);
  }
  remove(parsingIssueId: string, languageId: number): Observable<ApiResponse<boolean>> {
    let url = `${environment.publisherApiBaseUrl + IssueUrls.DeleteIssue}?issueId=${parsingIssueId}&languageId=${languageId}&websiteId=${this.currentWebsiteId}`;
    return this.http.delete<ApiResponse<boolean>>(url);
  }
  removeFromWebsite(parsingIssueId: string, languageId: number): Observable<ApiResponse<boolean>> {
    let url = `${environment.publisherApiBaseUrl + IssueUrls.UnPublishWebIssue}`;
    return this.http.post<ApiResponse<boolean>>(url, {IssueId:parsingIssueId,languageId:languageId , websiteId:this.currentWebsiteId});
  }

 SendMailForTest(parsingIssueId: string, languageId: number): Observable<ApiResponse<boolean>> {
    let url = `${environment.publisherApiBaseUrl + IssueUrls.SendIssueMailForTest}`;
    return this.http.post<ApiResponse<boolean>>(url, {IssueId:parsingIssueId,languageId:languageId, websiteId:this.currentWebsiteId});
  }
  SendMailForTestWithoutLink(parsingIssueId: string, languageId: number): Observable<ApiResponse<boolean>> {
     let url = `${environment.publisherApiBaseUrl + IssueUrls.SendIssueMailForTestWithoutLink}`;
     return this.http.post<ApiResponse<boolean>>(url, {IssueId:parsingIssueId,languageId:languageId, websiteId:this.currentWebsiteId});
   }
  getMailChimpTemplateContent( templateId:number): Observable<string> {
    let url = environment.publisherApiBaseUrl + IssueUrls.getMailChimpTemplateContentUrl+templateId;
    return this.http.get<string>(url);
  }


}
