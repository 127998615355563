<div class="card card-custom gutter-b">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">Mail Version</h3>
            <ng-container class="d-flex justify-content-center overlay-layer bg-transparent" *ngIf="isLoading$ | async">
                <div class="spinner spinner-lg spinner-success spinner-center" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="card-body ">
        <!-- <div class="col-lg-12 mb-5" *ngIf="result.isPublishedMail">
            <p style="color: green;align-content: center;" class="text-center"> mail version is published</p>
        </div> -->

        <div class="form form-label-right" *ngIf="result">
            <div class="form-group row" [formGroup]="formGroup">
                <div class="col-lg-12">
                    <div class=" row">
                        <div class="col-lg-3">
                            <label class="form-label">Select Audience</label>
                            <select class="form-control" formControlName="listId">
                                <option *ngFor="let edition of mailChimLists_List" value="{{ edition.id }}">
                                    {{ edition.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3">
                            <label class="form-label">Select Template</label>
                            <select class="form-control" formControlName="templateId"
                            (change)="onTemplateChange($event)">
                                <option value="0">No Template</option>
                                <option *ngFor="let edition of mailChimTemplateList" value="{{ edition.id }}">
                                    {{ edition.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3">
                            <div>
                                <label>Mailchimp Campaign Title</label><span class="text-danger"> *</span>
                                <input  type="text" class="form-control" formControlName="mailchimpCampaignTitle"
                                    autocomplete="off" [class.is-invalid]="isControlInvalid('mailchimpCampaignTitle', formGroup)"
                                    [class.is-valid]="isControlValid('mailchimpCampaignTitle', formGroup)" />
                                <div class="valid-feedback" *ngIf="isControlValid('mailchimpCampaignTitle', formGroup)">
                                    Mailchimp Campaign Title email was entered correct
                                </div>
                               
                                <div class="invalid-feedback"
                                    *ngIf="controlHasError('cannotContainSpace', 'mailchimpCampaignTitle', formGroup)">
                                    Mailchimp Campaign Title email is required
                                </div>
                                <div class="invalid-feedback" *ngIf="controlHasError('length', 'mailchimpCampaignTitle', formGroup)">
                                    Mailchimp Campaign Title should have at least 5 symbols and maximum 100 symbols
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class=" row">
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>From Name</label><span class="text-danger"> *</span>
                                <input matInput type="text" class="form-control" formControlName="fromName"
                                    autocomplete="off" [class.is-invalid]="isControlInvalid('fromName', formGroup)"
                                    [class.is-valid]="isControlValid('fromName', formGroup)" />

                                <div class="invalid-feedback"
                                    *ngIf="controlHasError('cannotContainSpace', 'fromName', formGroup)">
                                    From Name is required
                                </div>
                                <div class="invalid-feedback" *ngIf="controlHasError('length', 'fromName', formGroup)">
                                    From Name should have at least 5 symbols and maximum 100 symbols
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>Reply To</label><span class="text-danger"> *</span>
                                <input matInput type="text" class="form-control" formControlName="replyTo"
                                    autocomplete="off" [class.is-invalid]="isControlInvalid('replyTo', formGroup)"
                                    [class.is-valid]="isControlValid('replyTo', formGroup)" />
                                <div class="valid-feedback" *ngIf="isControlValid('replyTo', formGroup)">
                                    Reply to email was entered correct
                                </div>
                                <div class="valid-feedback" *ngIf="controlHasError('email','replyTo', formGroup)">
                                    Reply to email was entered correct
                                </div>
                                <div class="invalid-feedback"
                                    *ngIf="controlHasError('cannotContainSpace', 'replyTo', formGroup)">
                                    Reply to email is required
                                </div>
                                <div class="invalid-feedback" *ngIf="controlHasError('length', 'replyTo', formGroup)">
                                    Reply to email should have at least 10 symbols and maximum 100 symbols
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <angular-editor #editor id="angular-editor" [config]="config" name="webHTML" matInput
            [(ngModel)]=" this.issueContent"></angular-editor>
        <div></div>
        <!-- </div> -->

    </div>
    <div class="row right mt-5">
        <button class="btn btn-primary btn-elevate mr-2" (click)="copyText()">
            Copy Content
        </button>
        <button type="submit" class="btn btn-primary btn-elevate  mr-2" (click)="SendMailForTest(); ">
            Send to test
        </button>
        <button type="submit" [disabled]="( submitted)"
            class="btn btn-primary btn-elevate  mr-2" (click)="publishIssueMailVersion(); ">
            Send to MailChimp
        </button>

    </div>

</div>