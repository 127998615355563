import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './@core/auth/auth.guard';

export const routes: Routes = [
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: 'view-mail-issue',
    loadChildren: () =>
      import('./pages/view-mail-issue/view-mail-issue.module').then((m) => m.ViewMailIssueModule),
  },
  {
    path: 'app-view-mail-issue-withoutlink',
    loadChildren: () =>
      import('./pages/view-mail-issue-withoutlink/view-mail-issue-withoutlink.module').then((m) => m.ViewMailIssueWithoutlinkModule),
  },
  {
    path: 'error',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
