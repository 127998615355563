import { Injectable } from '@angular/core';
import { LoginResponse, LoginUser, RefreshToken, SocialLoginUser, User } from './app-user';
import { UserToken, UserRefreshToken } from './app-user-token';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUserInto } from './app-user-into';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { CryptoService } from 'src/app/@AppService/services/crypto/crypto.service';
import { ApiResponse } from 'src/app/_metronic/shared/crud-table/models/response.model';
import { AuthUrls } from 'src/app/@AppService/Common/ServiceUrls';
import { LoginTypeEnum } from 'src/app/@AppService/Enums/login.type.enum';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSessionName = 'enterprise-currentUser';
  private userTokenSessionName = 'enterprise-currentUserToken';
  private userNotificationConnectionIdName = 'enterprise-notificationConnectionId';
  private userRefreshTokenSessionName = 'enterprise-currentUserRefreshToken';
  private userInfo = 'enterprise-currentUserInfo';
  private currentActiveAcount = 'enterprise-currentActiveAcount';
  private loginType = 'enterprise-loginType';
  public activeWebSiteName = 'enterprise-activeWebsite';


  private googleUser: SocialUser = new SocialUser;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cryptoService: CryptoService,
    private msalService: MsalService,
    private googleService: SocialAuthService
  ) { }

  public get currentUserInfo(): AppUserInto {
    return this.getCurrentUserToken();
  }
  public get currentUserToken(): UserToken {
    return this.getUserToken();
  }
  public get currentUserRefreshToken(): string {
    return this.getUserRefreshToken();
  }

  public get currentLoginType(): number {
    return this.GetCurrentloginType();
  }



  isUserLoginedHasValidToken() {
    const jwtHelper = new JwtHelperService();

    return this.getUser() != null && !jwtHelper.isTokenExpired(this.currentUserToken.idToken);
  }
  login(
    account: any,
    loginUserMail: string,
    loginFullName: string,
    loginImageUrl: string,
    loginIdToken: string,
    loginAccessToken: string,
    loginRefreshToken: string,
    loginType: number,
  ) {
    const user: User = {
      userFullName: loginFullName,
      userMail: loginUserMail,
      imageUrl: loginImageUrl,
    };


    const token: UserToken = { idToken: loginIdToken, accessToken: loginAccessToken };
    const refreshtoken: UserRefreshToken = { refreshToken: loginRefreshToken };

    localStorage.setItem(this.userSessionName, JSON.stringify(user));
    localStorage.setItem(this.userTokenSessionName, JSON.stringify(token));
    localStorage.setItem(this.userRefreshTokenSessionName, JSON.stringify(refreshtoken));
    localStorage.setItem(this.loginType, JSON.stringify(loginType));

    if (this.currentLoginType == LoginTypeEnum.Azure) {
      this.getMicrosoftProfilePhoto(loginAccessToken);
      this.setMicrosoftActiveAccount(account);
      this.getMicrosoftUserInfo();
    }

  }
  logout() {
    // debugger
    // remove user from local storage to log user out
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.sessionStorage.removeItem('redirectLink');
  }
  updateTokens(newIdToken: string, newAccessToken: string, newRefreshToken: string) {
    const token: UserToken = { idToken: newIdToken, accessToken: newAccessToken };
    const refreshtoken: UserRefreshToken = { refreshToken: newRefreshToken };

    localStorage.setItem(this.userTokenSessionName, JSON.stringify(token));
    localStorage.setItem(this.userRefreshTokenSessionName, JSON.stringify(refreshtoken));
    if (this.currentLoginType == LoginTypeEnum.Azure) {
      this.getMicrosoftUserInfo();
    }

  }
  refreshToken(): Observable<boolean> {
    let loginTypeId = this.currentLoginType;

    switch (loginTypeId) {
      case LoginTypeEnum.Azure:

        const accessTokenRequest = {
          scopes: ["openid", "profile", "User.Read", "User.Read.All"],
          account: this.getActiveAccount()
        };
        this.msalService.instance.acquireTokenSilent(accessTokenRequest).then((res) => {
          if (res != null && res.account != null) {
            this.logout();
            this.msalService.instance.setActiveAccount(res.account);
            this.login(
              res.account,
              res.account.username,
              res.account.name,
              '',
              res.idToken,
              res.accessToken,
              '',
              LoginTypeEnum.Azure
            );
          }
          else {
            this.logout();
            this.router.navigate(['']);

          }
        }).catch(data => {
          this.logout();
          this.router.navigate(['']);
        });
        return of(true);

      case LoginTypeEnum.Google:

        this.googleService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then(() => {
        });
        this.googleService.authState.subscribe((res) => {
          if (res != null && res.idToken != null) {
            this.logout();
            this.googleUser = res;
            this.login(
              this.googleUser,
              this.googleUser.email,
              this.googleUser.name,
              this.googleUser.photoUrl,
              this.googleUser.idToken,
              this.googleUser.response.access_token,
              '',
              LoginTypeEnum.Google
            );
          }
          else {
            this.logout();
            this.router.navigate(['']);
          }
        });
        return of(true);
      case LoginTypeEnum.JWT:
        this.
          refreshJwtToken({
            refreshToken: this.getUserRefreshToken()
          } as RefreshToken)

          .subscribe((loginResponse) => {
            if (loginResponse.isValidResponse && loginResponse.result.responseData) {
              this.logout();
              this.login(
                '',
                loginResponse.result.responseData.userEmail,
                loginResponse.result.responseData.userName,
                '',
                loginResponse.result.responseData.authToken,
                loginResponse.result.responseData.authToken,
                loginResponse.result.responseData.refreshToken,
                LoginTypeEnum.JWT
              );
            }
            else {
              this.logout();
              this.router.navigate(['']);
            }

          });
        return of(true);
    }

  }

  public currentUser(): User {
    return this.getUser();
  }
  public currentUserObservable(): Observable<User> {
    return of(this.getUser());
  }
  public updateUserImage(imageUrl) {
    if (imageUrl) {
      const userStorage = JSON.parse(localStorage.getItem(this.userSessionName));
      if (userStorage !== null && userStorage !== undefined) {
        const user = userStorage as User;;
        user.imageUrl = `data:image/png;base64,${imageUrl}`;
        localStorage.setItem(this.userSessionName, JSON.stringify(user));
      }
    }
  }
  public saveUserNotificationId(notificationId: string) {
    localStorage.setItem(this.userNotificationConnectionIdName, notificationId);
  }
  public getUserNotificationId() {
    return localStorage.getItem(this.userNotificationConnectionIdName);
  }
  public setMicrosoftActiveAccount(account: any) {
    if (account) {
      localStorage.setItem(this.currentActiveAcount, this.cryptoService.encryptObject(account));
    }
  }
  public getActiveAccount(): any {
    let activeAccount = localStorage.getItem(this.currentActiveAcount);
    if (!activeAccount) {
      return null;
    }
    return this.cryptoService.decryptObject(activeAccount);
  }

  public GetCurrentloginType(): number {
    let currentloginType = localStorage.getItem(this.loginType)
    if (!currentloginType) {
      return null;
    }
    return Number(currentloginType);
  }

  private getUser(): User {
    const userStorage = JSON.parse(localStorage.getItem(this.userSessionName));
    let userObj: User = null;
    if (userStorage !== null && userStorage !== undefined) {
      userObj = userStorage as unknown as User;
    }

    return userObj;
  }

  private getUserToken(): UserToken {
    let token = {} as UserToken;
    const userStorage = JSON.parse(
      localStorage.getItem(this.userTokenSessionName)
    );
    let userObj: UserToken = null;
    if (userStorage !== null && userStorage !== undefined) {
      userObj = userStorage as unknown as UserToken;
      token = userObj;
    }

    return token;
  }

  private getUserRefreshToken(): string {
    let token = '';
    const userStorage = JSON.parse(
      localStorage.getItem(this.userRefreshTokenSessionName)
    );
    let userObj: UserRefreshToken = null;
    if (userStorage !== null && userStorage !== undefined) {
      userObj = userStorage as unknown as UserRefreshToken;
      token = userObj.refreshToken;
    }

    return token;
  }

  private getCurrentUserToken(): AppUserInto {

    let userInfo = {} as AppUserInto;
    const userStorage = JSON.parse(localStorage.getItem(this.userInfo));
    let userObj: AppUserInto = null;
    if (userStorage !== null && userStorage !== undefined) {
      userObj = userStorage as unknown as AppUserInto;
      userInfo = userObj;
    }
    return userInfo;
  }

  private getMicrosoftUserInfo() {
    let userToken = this.currentUserToken;
    if (userToken) {
      let headers = new HttpHeaders({
        Authorization: 'Bearer ' + userToken.accessToken,
        'Content-Type': 'application/json'
      });
      this.http.get<AppUserInto>(environment.office365UserInfoUrl, {
        headers: headers,
      }).subscribe(info => {
        localStorage.setItem(this.userInfo, JSON.stringify(info));
      })
    }
  }

  private getMicrosoftProfilePhoto(accessToken) {
    if (accessToken) {
      let headers = new HttpHeaders({
        'Content-Type': 'image/*',
        Authorization: 'Bearer ' + accessToken,
      });
      this.http.get(environment.office365UserPictureUrl, {
        headers: headers,
        responseType: 'arraybuffer',
      })
        .toPromise()
        .then(
          (data) => {
            const TYPED_ARRAY = new Uint8Array(data);
            const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
            let base64String = btoa(STRING_CHAR);
            this.updateUserImage(base64String);
            window.location.reload();
          },
          (err) => {
            console.log("There's no picture found for this user !!!")
            this.router.navigate(['/dashboard']);
          }
        );
    }
  }



  loginUser(loginUser: LoginUser): Observable<ApiResponse<LoginResponse>> {
    return this.http.post<ApiResponse<LoginResponse>>(environment.sitelayoutApiBaseUrl + AuthUrls.LoginUrl, loginUser);
  }

  socialLoginUser(loginUser: SocialLoginUser): Observable<ApiResponse<LoginResponse>> {
    return this.http.post<ApiResponse<LoginResponse>>(environment.sitelayoutApiBaseUrl + AuthUrls.SocialLoginUrl, loginUser);
  }

  refreshJwtToken(refreshToken: RefreshToken): Observable<ApiResponse<LoginResponse>> {
    return this.http.post<ApiResponse<LoginResponse>>(environment.sitelayoutApiBaseUrl + AuthUrls.RefreshTokenUrl, refreshToken);
  }

  setActiveWebSite(webSiteId: number) {
    localStorage.setItem(this.activeWebSiteName, JSON.stringify(webSiteId));
  }

  public get activeWebSite(): number {
    const activeWebSite = JSON.parse(localStorage.getItem(this.activeWebSiteName));

    if (!activeWebSite) {
      return 1;
    }
    return Number(activeWebSite);
  }
}
