import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  constructor() { }

  encryptString(data: string): string {
    return CryptoJS.TripleDES.encrypt(data, environment.ENCRYPTION_KEY).toString();
  }
  decryptString(ciphertext: string): string {
    return CryptoJS.TripleDES.decrypt(ciphertext, environment.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
  }
  encryptObject(data: object): string {
    return CryptoJS.TripleDES.encrypt(JSON.stringify(data), environment.ENCRYPTION_KEY).toString();
  }
  decryptObject(ciphertext: string): object {
    let data = CryptoJS.TripleDES.decrypt(ciphertext, environment.ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return JSON.parse(data);
  }
}
