// tslint:disable:variable-name
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { ITableState, TableResponseModel } from '../models/table.model';
import { BaseModel } from '../models/base.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { IUrl } from '../models/url.model';
import { FileContent } from 'src/app/@AppService/models/file-content.model';
import { AuthenticationService } from 'src/app/@core/auth/authentication.service';
import { AppInjector } from 'src/app/@core/Injector/app-injectore';
import { Websites } from 'src/app/@AppService/Enums/security';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined,
  websiteId: Websites.EnterpriseNews
};

export abstract class TableService<T> {

  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];

  // Getters
  get items$() {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  get totalRecords() {
    return this._tableState$.value.paginator.total;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }

  get currentWebsiteId(){
    return this.authentedService.activeWebSite
  }

  // URLS
  Urls: IUrl = {
    ListUrl: '',
    GetByIdUrl: '',
    CreateUrl: '',
    UpdateUrl: '',
    DeleteUrl: '',
    DeleteListUrl: '',
    ChangeStatusUrl: '',
    ActivateDeavtivateUrl: '',
    DDLUrl: '',
    UploadFileUrl: '',
    GetByIdForViewUrl: '',
    
  };

  protected http: HttpClient;
  protected authentedService: AuthenticationService;
  constructor(http: HttpClient) {
    this.http = http;

    const injector = AppInjector.getInjector();
    this.authentedService = injector.get(AuthenticationService);

  }

  // READ (Returning filtered list of entities)
  find(tableState: ITableState): Observable<TableResponseModel<T>> {
    this._errorMessage.next('');
    return this.http
      .post<TableResponseModel<T>>(this.Urls.ListUrl, tableState)
      .pipe(
        map((response: any) => {
          const result: TableResponseModel<T> = {
            items: response.dataList,
            total: response.totalCount,
          };

          return result;
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          console.error('FIND ITEMS', err);
          return of({ items: [], total: 0 });
        })
      );
  }

  getItemById(id: number): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    return this.http.get<BaseModel>(this.Urls.GetByIdUrl + '?id=' + id).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        return of({ id: undefined, activationStatus: '' });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getItem(url: string): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    return this.http.get<BaseModel>(url).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('GET ITEM ', err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getItemByIdForView(id: number): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    return this.http
      .get<BaseModel>(this.Urls.GetByIdForViewUrl + '?id=' + id)
      .pipe(
        catchError((err) => {
          this._errorMessage.next(err);
          console.error('GET ITEM BY IT', id, err);
          return of({ id: undefined, activationStatus: '' });
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  getDDL(ddlURL: string): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.get<any>(ddlURL).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('GET DDL', err);
        return of({ ddls: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  uploadFile(files: File, uploadType: any): Observable<FileContent> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const formData = new FormData();
    formData.append('file', files);
    // formData.append('uploadType',uploadType);
    return this.http
      .post<FileContent>(
        this.Urls.UploadFileUrl + '?uploadType=' + uploadType + '&websiteId'+this.authentedService.activeWebSite,
        formData
      )
      .pipe(
        map((response: FileContent) => {
          return response;
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          console.error('Upload ITEM', err);
          return of({ fileName: '', imageUrl: '' });
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  // CREATE
  // server should return the object with ID
  create(item: T): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<BaseModel>(this.Urls.CreateUrl, item).pipe(
      // map((response: any) => {
      //   return response;
      // }),
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined, activationStatus: '' });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: T): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(this.Urls.UpdateUrl, item).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.delete(this.Urls.DeleteUrl + '?id=' + id).pipe(
      // map((response: any) => {
      // console.log("Del Response")
      // console.log(response);
      // return response;
      // }),
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    const body = { ids };
    return this.http.put(this.Urls.DeleteListUrl, body).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  // activate / deactivate
  activateDeactivate(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    return this.http.delete(this.Urls.ActivateDeavtivateUrl + '?id=' + id).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('Activate / Deactivate ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    return this.http.put(this.Urls.ChangeStatusUrl, body).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  httpGet(url: string): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.get<any>(url).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('Http GET :', err);
        return of({ ddls: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  httpPut(url: string, obj: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put<any>(url, obj).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('Http GET :', err);
        return of({ ddls: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0,
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = el as unknown as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ websiteId: this.authentedService.activeWebSite });
    
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState() });
    this.patchStateWithoutFetch({ searchTerm: '' });
    this.patchStateWithoutFetch({
      paginator: new PaginatorState(),
    });

  

    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public searchEntity() {
    this.fetch();
  }

  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }
  public restItemsToEmpty(){
    this._items$.next([]);
  }
}
