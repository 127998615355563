import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs/operators';
import { LanguageEnum } from 'src/app/@AppService/Enums/language-enum.enum';
import { PageEnum } from 'src/app/@AppService/Enums/page-enum.enum';
import { ParsingIssueDataForPublish, PublishIssueMailVersionDto, SearchParsingIssueData } from 'src/app/@AppService/models/atomizer.model';
import { AtomizerService } from 'src/app/@AppService/services/atomizer.service';
import { ParsingIssueService } from 'src/app/@AppService/services/parsing-issue.service';
import { PrivilgeService } from 'src/app/@AppService/services/privilge.service';
import { BaseComponent } from 'src/app/@core/Component/BaseComponent/BaseComponent';
import { ApiResponse } from 'src/app/_metronic/shared/crud-table/models/response.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { DDLModel } from 'src/app/@AppService/models/common.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { textValidator } from 'src/app/@core/Validator/data-validator';

@Component({
  selector: 'app-view-mail-issue',
  templateUrl: './view-mail-issue.component.html',
  styleUrls: ['./view-mail-issue.component.scss']
})
export class ViewMailIssueComponent extends BaseComponent implements OnInit {


  @Input() parsingIssueId: string;
  @Input() languageId: number;
  //@Input() isParent: boolean;
  @Input() editionId: number;
  mailChimLists_List: DDLModel[] = [];
  mailChimTemplateList: DDLModel[] = [];
  selectedListId = '';
  selectedTemplateId = 0
  templateContent = '';
  issueContent = ''
  result: ParsingIssueDataForPublish
  isLoading$;
  formGroup: FormGroup;
  arabicFromName: string = 'إنتربرايز';
  englishFromName: string = 'Enterprise';
  replyTo: string = 'enterprise@inktankcommunications.com';
  constructor(
    private clipboard: Clipboard,
    public parsingIssueService: ParsingIssueService,
    public atmoizerService: AtomizerService,
    public privilgeService: PrivilgeService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    super(PageEnum.Issue);
    this.baseActivatedRoute.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((val) => {
        if (val.data) {
          this.parsingIssueId = val.data.parsingIssueId;
          this.languageId = val.data.languageId;
          this.editionId = val.data.editionId;
        }
        else {
          this.back();
        }
      });
  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.parsingIssueId = params['id'];
      this.languageId = params['languageId'];
      this.editionId = params['editionId'];
    });
    this.getIssueData();
    this.isLoading$ = this.parsingIssueService.isLoading$;

  }

  loadForm(): void {

    this.formGroup = this.fb.group({
      listId: [this.selectedListId,
      Validators.compose([
        Validators.required,
      ]),
      ],
      templateId: [0],
      replyTo: [this.replyTo, Validators.compose([Validators.email]),],
      mailchimpCampaignTitle: [''],
      fromName: [this.languageId == this.languageEnum.arabic ? this.arabicFromName : this.englishFromName],
    },
      {
        validators: [textValidator('replyTo', true, 10, 100), textValidator('mailchimpCampaignTitle', true, 5, 100), textValidator('fromName', true, 5, 100)],
      }
    );
    //this.selectedRole =0 ;
  }

  back() {
    // this.navigateToUrl('/atomizer/parsing-issue-list');

  }
  get languageEnum() { return LanguageEnum; }
  getIssueData() {

    this.parsingIssueService
    let searchDto: SearchParsingIssueData = {
      issueId: this.parsingIssueId,
      // isParent: this.isParent,
      languageId: this.languageId,
      editionId: this.editionId,
      websiteId:this.authentedService.activeWebSite
    }
    this.parsingIssueService.getIssueData(searchDto)
      .subscribe((response: ApiResponse<ParsingIssueDataForPublish>) => {
        if (response.isValidResponse) {
          this.result = response.result.responseData;
          this.selectedListId = this.result.listId;
          this.issueContent = this.result.mailHTML;
          //  this.isActive = this.result.isPublishedMail
          this.getMailChimpTemplateDDL();
          this.getListsDDL();
          this.loadForm()
        }
      }),
      catchError((errorMessage) => {
        console.log("errorMessage" + errorMessage);

        this.back();
        return of();
      })

  }
  private getListsDDL() {
    let url = this.parsingIssueService.getMailChimpListsUrl;
    this.parsingIssueService.getDDL(url).subscribe(
      (response: DDLModel[]) => {
        if (response.length > 0) {
          this.mailChimLists_List = response;
        }
      },
      (err) => {
        this.errorOccured(err);
      }
    );
  }
  onTemplateChange($event) {

    let selectedOption =
      $event.target.options[$event.target.options.selectedIndex];
    this.getIssueTemplate(selectedOption.value);
  }

  private getIssueTemplate(templateId: number) {

    if (templateId !== 0) {
      let url = this.parsingIssueService.getMailChimpListsUrl;
      this.parsingIssueService.getMailChimpTemplateContent(templateId).subscribe(
        (response: string) => {
          if (response) {
            this.templateContent = response;
            this.issueContent = response;
            this.issueContent = this.issueContent.replace("Edit Me", this.result.mailHTML);
          }
        },
        (err) => {
          this.errorOccured(err);
        }
      );
    } else {
      this.issueContent = this.result.mailHTML;
    }
  }

  private getMailChimpTemplateDDL() {
    let url = this.parsingIssueService.getMailChimpTemplatesUrl;
    this.parsingIssueService.getDDL(url).subscribe(
      (response: DDLModel[]) => {
        if (response.length > 0) {
          this.mailChimTemplateList = response;
        }
      },
      (err) => {
        this.errorOccured(err);
      }
    );
  }

  publishIssueMailVersion() {

    if (this.formGroup.invalid)
      return;

    const formData = this.formGroup.value;
    this.submitted = true;
    let publishIssueMailVersionDto: PublishIssueMailVersionDto = {
      issueId: this.result.issueId,
      languageId: this.languageId,
      templateId: formData.templateId,
      listId: formData.listId,
      replyTo: formData.replyTo,
      mailchimpCampaignTitle: formData.mailchimpCampaignTitle,
      fromName: formData.fromName, 
      websiteId:this.authentedService.activeWebSite
    }
    if (!this.result.isPublishedMail) {
      this.atmoizerService
        .publishIssueMail(publishIssueMailVersionDto)
        .pipe(
          map((response: ApiResponse<boolean>) => {
            if (response.isValidResponse) {
              this.showSuccessMessage(response.result.commandMessage);
              this.result.isPublishedMail = true;
              this.submitted = false;
            } else {
              if (response.errors) {
                this.showErrorMessages(response.errors);
              }
              if (response.result.commandMessage) {
                this.showErrorMessage(response.result.commandMessage);
              }
            }
          }),
          catchError((errorMessage) => {
            return of();
          })
        )
        .subscribe(() => { });
    }

  }


  SendMailForTest() {
    this.parsingIssueService.SendMailForTest(this.parsingIssueId, this.languageId)
      .pipe(
        map((response: ApiResponse<boolean>) => {
          if (response.isValidResponse) {
            this.showSuccessMessage(response.result.commandMessage);
            this.ngOnInit();
          } else {
            this.showErrorMessages(response.errors);
          }
        }),
        catchError((errorMessage) => {
          this.errorOccured(errorMessage);
          return of(errorMessage);
        })
      )
      .subscribe((response: any) => {

      });
  }

  copyText() {
    this.clipboard.copy(this.result.mailHTML);
    this.showSuccessMessage("THe content is copied successfully")
  }


  // ------------------- Editor -------------------
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '40rem',
    width: '100%',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'yes',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [[]],
    showToolbar: false,
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  // ------------------- Editor -------------------
}
