import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserToken } from '../auth/app-user-token';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string>;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthenticationService, protected router: Router) {
    this.urlsToNotUse = [
      environment.office365UserInfoUrl,
      environment.office365UserPictureUrl
    ];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let authRequest = request;
    if (this.isValidRequestForInterceptor(request.url)) {
      authRequest = this.addHeaders(request);
    }

    return next.handle(authRequest).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(authRequest, next);
      }

      return throwError(error);
    }));
  };

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    return !this.urlsToNotUse.includes(requestUrl);
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.authService.currentUserToken;

      if (token?.idToken) {
        return this.authService.refreshToken().pipe(
          switchMap((isValid: any) => {
            this.isRefreshing = false;
            const newToken = this.authService.currentUserToken;
            this.refreshTokenSubject.next(newToken.idToken);

            return next.handle(this.addHeaders(request));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.authService.logout();
            this.router.navigate(['/']);
            return throwError(err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      
      filter(isValid => isValid === true),
      take(1),
      switchMap((isValid) => next.handle(this.addHeaders(request)))
    );
  }
  private addHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let authRequest = request;
    const userToken = this.authService.currentUserToken;
    if (userToken) {
      authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${userToken.idToken}`,
          ['access-token']: `Bearer ${userToken.accessToken}`
        }
      });
    }
    return authRequest;
  }
}
