import { AtmoizerUrls, ServiceUrls } from '../Common/ServiceUrls';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TableService } from 'src/app/_metronic/shared/crud-table';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IssueContent, MailResultDto, PublishIssueMailVersionDto, PublishIssueWebVersionDto, ValidationSummaryDto } from '../models/atomizer.model';
import { ApiResponse } from 'src/app/_metronic/shared/crud-table/models/response.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AtomizerService
  extends TableService<IssueContent>
  implements OnDestroy {

  GetTestMailResult: string;
  ParseIssue: string;
  PublishIssueWebVersion: string;
  publishIssueMailVersion: string;
  publishIssueMailVersionWithoutLink: string;
  ValidateIssueUrl:string;
  
  constructor(@Inject(HttpClient) http) {
    super(http);

    this.Urls.CreateUrl =
      environment.publisherApiBaseUrl + AtmoizerUrls.ParseAndSaveIssue;

    this.Urls.UpdateUrl =
      environment.publisherApiBaseUrl + AtmoizerUrls.UpdateIssue;

    this.ParseIssue = environment.publisherApiBaseUrl + AtmoizerUrls.ParseIssue;

    this.ValidateIssueUrl = environment.publisherApiBaseUrl + AtmoizerUrls.ValidateIssue;

    this.GetTestMailResult = environment.publisherApiBaseUrl + AtmoizerUrls.GetTestMailResult;

    this.Urls.GetByIdUrl =
      environment.publisherApiBaseUrl + AtmoizerUrls.GetIssue;
    this.PublishIssueWebVersion = environment.publisherApiBaseUrl + AtmoizerUrls.PublishIssueWebVersion;
    this.publishIssueMailVersion = environment.publisherApiBaseUrl + AtmoizerUrls.PublishIssueMailVersion;
    this.publishIssueMailVersionWithoutLink = environment.publisherApiBaseUrl + AtmoizerUrls.PublishIssueMailVersionWithoutLink;
    this.Urls.UploadFileUrl = environment.sitelayoutApiBaseUrl + ServiceUrls.UploadFile;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }
  getMailTestResult(glockAppId: string): Observable<ApiResponse<MailResultDto>> {
    let url = `${this.GetTestMailResult}?testId=${glockAppId}`;
    return this.http.get<ApiResponse<MailResultDto>>(url);
  }

  ValidateIssue(item:IssueContent): Observable<ApiResponse<ValidationSummaryDto>>
  {
    return this.http.post<ApiResponse<ValidationSummaryDto>>(this.ValidateIssueUrl,item);
  }

  publishIssueWebVersion(item: PublishIssueWebVersionDto): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(this.PublishIssueWebVersion, item);
  }
  
  publishIssueMail(item: PublishIssueMailVersionDto): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(this.publishIssueMailVersion, item);
  }

  
  publishIssueMailWithoutLink(item: PublishIssueMailVersionDto): Observable<ApiResponse<boolean>> {
    return this.http.post<ApiResponse<boolean>>(this.publishIssueMailVersionWithoutLink, item);
  }

  getIssueData(parentParsingIssueId: string, languageId: number): Observable<ApiResponse<IssueContent>> {
    let url = `${this.Urls.GetByIdUrl}?issueId=${parentParsingIssueId}&languageId=${languageId}&websiteId=${this.currentWebsiteId}`;
    return this.http.get<ApiResponse<IssueContent>>(url);
  }

  getValidationSummary(parentParsingIssueId: string, languageId: number): Observable<ApiResponse<IssueContent>> {
    let url = `${environment.publisherApiBaseUrl + AtmoizerUrls.GetValidationSummary}?issueId=${parentParsingIssueId}&languageId=${languageId}`;
    return this.http.get<ApiResponse<IssueContent>>(url);
  }
}
