import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
import { PageChangingEvent } from './@core/events/page-change-event';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CommonModule, DatePipe } from '@angular/common';
import { ImagePreviewComponent } from './@core/Component/image-preview/image-preview.component';
import { ConfirmationComponent } from './@core/Component/confirmation/confirmation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { AuthenticationService } from './@core/auth/authentication.service';
import { WelcomeModule } from './pages/welcome/welcome.module';
import { WelcomePageComponent } from './pages/welcome/welcome-page/welcome-page.component';
import { MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { SocialLoginModule,SocialAuthServiceConfig, GoogleLoginProvider } from "angularx-social-login";
import { ScrollingModule } from '@angular/cdk/scrolling';

import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { AppInjector } from './@core/Injector/app-injectore';
import { environment } from 'src/environments/environment';
import { JwtInterceptor } from './@core/Interceptor/jwt.interceptor';
import { ChartModule } from 'angular-highcharts';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ViewMailIssueModule } from './pages/view-mail-issue/view-mail-issue.module';
import { ErrorInterceptor } from './@core/Interceptor/error.interceptor';
import { FrontendSponserComponent } from './modules/frontend-sponser/frontend-sponser.component';
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: '1ae74c99-db15-4481-a478-c0b8759a990d',
      // redirectUri: 'http://localhost:4200/',
      clientId: environment.office365ClientId,
      redirectUri: environment.office365RedirectUrl,
    },
  });
}

function appInitializer(authService: AuthenticationService) {
  return () => {

    return authService.currentUser();
  };
}
const CLIENT_ID = environment.googleClientId;

@NgModule({
  declarations: [AppComponent, FrontendSponserComponent],
  entryComponents: [ImagePreviewComponent, ConfirmationComponent,WelcomePageComponent],
  imports: [
    BrowserModule,
    GoogleMapsModule,
    CommonModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    ClipboardModule,
    WelcomeModule,
    ViewMailIssueModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    SocialLoginModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule ,
    ScrollingModule,

  ],
  providers: [
    DatePipe,
    NgbActiveModal,
    PageChangingEvent,
    ToastrService,

    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService],
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              CLIENT_ID,
            ),
          }
        ]
      } as SocialAuthServiceConfig
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    MsalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
