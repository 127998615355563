import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpClient,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { Router } from '@angular/router';
import { GenericService } from 'src/app/@AppService/services/Base/GenericService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AppInjector } from '../Injector/app-injectore';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
 // private toastr: ToastrService;

  constructor(
 
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
  ) {
  //  const injector = AppInjector.getInjector();
   // this.toastr = injector.get(ToastrService);

  }


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    

    return next.handle(request).pipe(

      catchError((err) => {
        console.log(err);
        if (err.status === 401) {
          const refreshToken = this.authenticationService.currentUserRefreshToken;
          if (refreshToken != undefined && refreshToken != null && refreshToken != '') {
          
            // this.tryRefreshingTokens(refreshToken);
          } else {
            this.router.navigate(['/auth/login']);
          }
        } else if (err.status === 403) {
          status = 'danger';
         this.toastr.error('Invalid Privilege');
        //  this.openerroSnackBar('InvalidPrivilege');
          this.router.navigate(['/dashboard/index']);
        } else if (err.status === 500) {
          this.toastr.error('internal server error');
          this.toastr.error(err.error.message);
        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  // async tryRefreshingTokens(token) {
  //   let result = false;
  //   await this.http.get('./assets/settings/app.config.json').toPromise().then((value: any) => this.configuration$ = value);
  //   const data = await this.genericService.getDataAsyc<ResponseDto<RefreshResponse>>(
  //     this.configuration$.AuthApiBaseUrl + ServiceUrls.RefreshUrl,
  //     { refreshToken: token } as RefreshTokenDto
  //   );

  //   if (data.result === ResultStatusEnum.Success) {
  //     this.authenticationService.updateTokens(data.responseData.authToken, data.responseData.refreshToken);
  //     result = true;
  //   } else {
  //     this.router.navigate(['/auth/login']);
  //   }

  //   return result;
  // }


}
