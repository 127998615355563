export enum Pages {
  DashBoard = 1,
  
}

export enum SystemActions {
  View = 1200,
  Add = 1201,
  Edit = 1202,
  Delete = 1203,

}

export enum Websites {
  EnterpriseNews = 1,
  EnterpriseLogistics = 2,
}
