// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// https://pumpingco.de/blog/environment-variables-angular-docker/
export const environment = {
  production: true,
  appVersion: 'v0.1',
  USERDATA_KEY: 'authf649fc9a5f55',
  ENCRYPTION_KEY: '8fd41f0fb9804ffd907f00c8af26f762',
  WebsiteId: window['env']['WebsiteId'] || 0,

  office365ClientId: window['env']['office365ClientId'] || 'a4179ba3-06b1-460b-b026-b5ba27f3f81e',
  office365RedirectUrl: window['env']['office365RedirectUrl'] || 'http://localhost:4200/',
  googleClientId: window['env']['googleClientId'] || '609510109754-eqcju71kkr7ki1fsl0n5t68rr34vhabq.apps.googleusercontent.com',

  

  office365UserInfoUrl: window['env']['office365UserInfoUrl'] || 'https://graph.microsoft.com/oidc/userinfo',
  office365UserPictureUrl: window['env']['office365UserPictureUrl'] || 'https://graph.microsoft.com/v1.0/me/photo/$value',
 
  googleDriveRootId: window['env']['googleDriveRootId'] || '0ABq62ik1V0wVUk9PVA',
  googleIntregrationApiBaseUrl: window['env']['googleIntregrationApiBaseUrl'] || 'https://localhost:44308/api/',

  sitelayoutApiBaseUrl: window['env']['sitelayoutApiBaseUrl'] || 'https://localhost:44387/api/',
  VacancyApiBaseUrl: window['env']['VacancyApiBaseUrl'] || 'https://localhost:44314/api/',
  TicketApiBaseUrl: window['env']['TicketApiBaseUrl'] || 'https://localhost:44388/api/',
  podcastApiBaseUrl: window['env']['podcastApiBaseUrl'] || 'https://localhost:44322/api/',
  publisherApiBaseUrl: window['env']['publisherApiBaseUrl'] || 'https://localhost:44345/api/',
  SubscriptionApiBaseUrl: window['env']['SubscriptionApiBaseUrl'] || 'https://localhost:44309/api/',
  EnterpriseWebSiteUrl: window['env']['EnterpriseWebSiteUrl'] || 'https://enterpriselatest.enterprisedev.projectsarea.com/',

  issueEditorVersionUrl: window['env']['issueEditorVersionUrl'] || 'https://enterpriseeditorversion.enterprisedev.projectsarea.com/news/edition#',
  issueUrl: window['env']['issueUrl'] || 'https://enterprise.enterprisedev.projectsarea.com/news/edition#',
  
  //ValidateIssueUrl : window['env']['validateUrl'] || 'https://enterprise.enterprisedev.projectsarea.com/news/edition#',

  newsIssuebaseURl: window['env']['newsIssuebaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/egypt',
  englishNewsIssueRouting: window['env']['englishNewsIssueRouting'] || 'en/news/edition/',
  arabicNewsIssueRouting: window['env']['arabicNewsIssueRouting'] || 'ar/news/edition/',
  logisticsIssuebaseURl: window['env']['logisticsIssuebaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/logistics/en/news/edition/',
  climateIssuebaseURl: window['env']['climateIssuebaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/climate/en/news/edition/',
  KSAIssuebaseURl: window['env']['KSAIssuebaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/ksa/en/news/edition/',
  UEAIssuebaseURl: window['env']['UEAIssuebaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/uae/en/news/edition/',


  englishStoryRouting: window['env']['englishStoryRouting'] || '/en/news/story/',
  arabicStoryRouting: window['env']['arabicStoryRouting'] || '/ar/news/story/',

  logisticsStorybaseURl: window['env']['logisticsStorybaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/logistics/en/news/story/',
  climateStorybaseURl: window['env']['climateStorybaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/climate/en/news/story/',
  ksaStorybaseURl: window['env']['ksaStorybaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/ksa/en/news/story/',
  ueaStorybaseURl: window['env']['ueaStorybaseURl'] || 'https://websitelatest.enterprisedev.projectsarea.com/uae/en/news/story/',


};
