<div class="modal-content">

    <div class="modal-header">
        <h4 class="modal-title">{{messageTitle}}</h4>
    </div>


    <div class="modal-body">
        <p>
            {{messageBody}}
        </p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-elevate mr-2" ngbAutofocus (click)="modal.dismiss()">
            Cancel
        </button>
        <button type="submit" class="btn btn-danger btn-elevate" (click)="Delete()" [hidden]="!showDelete">
            Delete
        </button>
        <button type="submit" class="btn btn-primary btn-elevate" (click)="OK()" [hidden]="!showOk">
            OK
        </button>
    </div>

</div>