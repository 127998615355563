export enum confirmationType {
  Delete = 1,
  Activate = 2,
  Deactivate = 3,
  Resolve = 4,
  PublishEpisode = 5,
  SetAsDefault = 6,
  Hide = 7,
  Undelete = 8,
  Sync = 9,
  Copy = 10,
  RemoveFromWebsite = 11,
  RemoveImage = 12
}
