import { UserUrls } from '../Common/ServiceUrls';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TableService } from 'src/app/_metronic/shared/crud-table';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponse } from 'src/app/_metronic/shared/crud-table/models/response.model';
import { Observable } from 'rxjs';
import { UserPage } from '../models/user-page';
import { List } from 'linqts';
import { UserPageActionModel } from '../models/user-page-action-model';

@Injectable({
  providedIn: 'root',
})
export class PrivilgeService
  extends TableService<UserPage>
  implements OnDestroy {
  GetPagesUrl: string;
  GetPageActionsUrl: string;
  constructor(@Inject(HttpClient) http) {
    super(http);

    this.GetPagesUrl = environment.sitelayoutApiBaseUrl + UserUrls.GetUserPages;
    this.GetPageActionsUrl = environment.sitelayoutApiBaseUrl + UserUrls.GetUserPageActions;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  getUserPages(): Observable<ApiResponse<List<UserPage>>> {
    return this.http.get<ApiResponse<List<UserPage>>>(this.GetPagesUrl);
  }
  getUserPageActions(pageId: number): Observable<ApiResponse<UserPageActionModel>> {
    return this.http.get<ApiResponse<UserPageActionModel>>(this.GetPageActionsUrl + pageId);
  }
}
