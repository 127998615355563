export enum PageEnum {
    Dashboard = 1,
    SiteLayout = 2,
    SiteContent = 3,
    Tag = 4,
    Sponsor_Ads = 5,
    Vacancy = 6,
    Ticket = 7,
    Issue_Story = 8,
    Poll = 9,
    Podcast = 10,
    UserManagemet = 11,
    Language = 12,
    Edition = 13,
    DashboardLayoutDefinition = 14,
    ArticleLayoutDefinition = 15,
    MenuItem = 16,
    Changelogo = 17,
    AboutUs = 18,
    ContactUs = 19,
    TermsAndConditions = 20,
    Calendar = 21,
    Tag_Sub = 22,
    HashTag = 23,
    Sponsor = 24,
    SponserGroup = 25,
    Ad = 26,
    Vacancy_sub = 27,
    Applicant = 28,
    Department = 29,
    Ticket_Sub = 30,
    IssueCategory = 31,
    Issue = 32,
    ReplyTemplate = 33,
    Vertical = 34,
    StoryCategory = 35,
    ParsingIssue = 36,
    Story_Sub = 37,
    StoryCategoryLink = 38,
    SpecialSectionHead = 39,
    Poll_Sub = 40,
    PodcastStory = 41,
    PodcastEpisode = 42,
    User = 43,
    Role = 44,
    Atomizer = 45,
    ParsingResultTab = 46,
    ImportDocs = 47,
    PodcastLayout = 48,
    StoryTag = 49,
    SuperTag = 50,
    Media = 51,
    PageContent = 52,
    VacancyCategory=53,
    Reference = 56,
    ReferenceDocument = 57,
    FrontendSponser=59,
    MarketPrice = 60,
    ArchiveStory = 61,

}