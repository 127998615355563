<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title h4" id="example-modal-sizes-title-lg">
      Image preview
    </div>
  </div>
  <div class="overlay overlay-block cursor-default modal-body">
    <div class="col-md-12">
      <img src="{{ source }}" style="width: 100%;" loading="lazy" />
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-elevate mr-2" (click)="modal.dismiss()">
      Close
    </button>
  </div>
</div>
